/* globals, navigator */
import React, {useEffect, useState} from 'react'
import {Route, Link} from 'react-router-dom'
import ReactGA from 'react-ga'
import Helmet from 'react-helmet'
import gradesOnly from '../utils/gradeOrTest.js'
import './Program.css'
import Badge from './Badge/Badge.js'

const SchoolItem = ({program}) => {
    let [recomend, setRecomend] = useState(null)
    let [highEdu, setHighEdu] = useState(null)
    let [back, setBack] = useState(null)
    let [est, setEst] = useState(null)
    let [exitScore, setExitScore] = useState(null)
    let [error, setError] = useState(false)
    let [feedback, setFeedback] = useState(false)
    let [badfeedback, setBadfeedback] = useState(false)
    let [badFeedbackValue, setBadFeedbackValue] = useState('')

    const dev = document.location.hostname.search("betygen") === -1

    useEffect(()=>{
      console.log("-------ADS------");
      console.log(program);
      if(program !== undefined && !dev) {
        (window.adsbygoogle = window.adsbygoogle || []).push({})
      }
    }, [program])

    useEffect(() => {
      if (program === undefined) {return}
      ReactGA.pageview(window.location.pathname + window.location.search)
      console.log(program.name)
      fetch('/api', {
        method: 'POST',
        body: JSON.stringify({school: program.namn, program: program.program, munic: program.area}),
        headers:{
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(r => {
          setHighEdu(r.high)
          setBack(r.back)
          setEst(r.est)
          if(r.number === null) {
            console.log('no number');
            setError(true)
            return
          }
          fetch(`https://api.skolverket.se/planned-educations/school-units/${r.number}/statistics/gy`)
            .then(res => {
              if (!res.ok) {
                throw Error(res.statusText)
              }
              return res
            })
            .then(res => res.json())
            .then(d => {
              // console.log(d);
              let val = matchCorrectProg(d.programMetrics, program.program)
              setExitScore(val)
            })
            .catch(e => {console.log(e); setError(true)})
          fetch(`https://api.skolverket.se/planned-educations/school-units/${r.number}/surveys/pupilsgy`)
            .then(res => {
              if (!res.ok) {
                throw Error(res.statusText)
              }
              return res
            })
            .then(res => res.json())
            .then(d => setRecomend(d.schoolYearMetrics[0].recommendAverage))
            .catch(e => {console.log(e); setError(true)})
        })
    }, [program])
    if (program === undefined) {return null}
    let inflString = null
    let equivalent = null
    console.log(program)
    let year = '2020'
    if (program.inflation.high !== null) {
      inflString = `${program.inflation.high.toLocaleString(undefined, {maximumFractionDigits: 1})}% av alla elever har högre kursbetyg än resultat på nationella kursprov. `
    } else {program.inflation.high = 0}
    let lowString = `Antagningen ${year} fanns ingen antagningspoäng för ${program.program} på ${program.namn} (detta kan bero på att alla sökande blev antagna). `
    switch (program.antSlut) {
      case "1)":
        lowString = `Antagningen ${year} blev alla sökande antagna till ${program.program} på ${program.namn}. `
        break;
      case "2)":
        lowString = `Antagningen ${year} använde sig ${program.program} på ${program.namn} enbart av test. `
        break;
      case "3)":
        lowString = `Antagningen ${year} fanns ingen behörig sökande till ${program.program} på ${program.namn}. `
        break;
      case "5)":
        lowString = `Antagningen ${year} gjordes antagningen manuellt av ${program.namn} till ${program.program}. `
        break;
      default:
        if (program.antSlut !== null) {
          lowString = (<React.Fragment>Gymnasieantagningen {year} var antagninspoängen <b>{program.antSlut}</b> poäng {calcEquivalentGrade(program.antSlut) !== '' ? ` (${calcEquivalentGrade(program.antSlut)})` : ''} för {program.program} på {program.namn}. </React.Fragment>)
        }
    }
    let veryHighStr = null
    if (program.antSlut > 340) {
      veryHighStr = ' Att programmet hade antagningspoäng över 340 poäng kan bero på att resultat från t.ex. inträdesprov adderas till de vanliga betygen.'
    }
    let avgStrig = null
    if (program.medSlut !== null) {
      avgStrig = <React.Fragment>Genomsnittspoängen på programmet är <b>{program.medSlut}</b> poäng{calcEquivalentGrade(program.medSlut) !== '' ? ` (${calcEquivalentGrade(program.medSlut)})` : ''}.</React.Fragment>
    }
    let shortTitleString = program.urlString
    let limit = 35
    if(shortTitleString.length > limit) {
      shortTitleString = shortTitleString.substr(0, limit-3) +  '…'
    }
    let structuredData = JSON.stringify({
      "@context": "http://schema.org/",
      "@type": "Course",
      "name":  program.program,
      "courseCode":  program.program,
      "description": `${program.urlString} antagningspoängen var ${program.antSlut} poäng`,
      "coursePrerequisites": `${program.antSlut} poäng`,
      "provider": {
        "@type": "HighSchool",
        "name":  program.namn,
        "aggregateRating": recomend !== null && recomend !== '-' ? Number(recomend.replace(',', '.')) : 0.0,
        "areaServed": program.area
      }
    })

    const sendFeedback = (good) => {
      // console.log(good)
      ReactGA.event({
        category: 'Program ' + (good ? 'Good' : 'Bad'),
        action: 'Feedback',
        value: 1
      })
      ReactGA.event({
        category: 'ProgramExpl ' + (good ? 'Good ' : 'Bad ') + program.urlString,
        action: 'Feedback',
        value: 1
      })
      if(!good){
        setBadfeedback(true)
      }
      setFeedback(true)
    }

    const handleSendBadFeedback= () => {
      ReactGA.event({
        category: 'ProgFeedback: ' + badFeedbackValue + program.urlString,
        action: 'Feedback',
        value: 1
      })
      setBadfeedback(false)
    }

    return (
      <article>
        <Helmet>
          <title>🎓{shortTitleString} | Antagninspoäng 2020</title>
          <link rel="canonical" href={`https://www.betygen.com/program/${program.urlString}`} />
          <meta name="description" content={`Antagningspoäng 2020: ${program.program} på ${program.namn} hade ${program.antSlut===null?'0':program.antSlut} i antagningspoäng. Medelpoängen på programmet är ${program.medSlut} poäng`}></meta>
            <script type="application/ld+json">{structuredData}</script>
        </Helmet>
        <Route render={({history}) => {
            console.log(history);
            if (history.location.state && history.location.state.fromGood) {
              return <a className="about-link SchoolItem-back-link" onClick={()=>history.goBack()}>◀ Tillbaka</a>
            } else {
              return <Link className="about-link SchoolItem-back-link" to='/'>◀ Alla program</Link>
            }
          }}/>
        <h2 className='SchoolItem-school-title'><Link className='SchoolItem-detail-link' to={{
            pathname: `/skola/${encodeURI(encodeURIComponent(program.name))}`,
            state: {fromGood: true}
          }}>{program.namn}</Link><br/>{program.program}</h2>

        <h3 itemProp="areaServed">{program.area}</h3>
        <p>
          {lowString}
          {equivalent}
          {avgStrig}
          {veryHighStr}
        </p>
        <div className='SchoolItem-data-container'>
          <div className='SchoolItem-data-title-container'>
            <h3>Antagningsstatistik<br/><span>{program.program} på {program.namn}</span></h3>
            {navigator.share ? <h3 className='SchoolItem-data-share' onClick={()=>{if(navigator.share){navigator.share({url: window.location.href})}}}>Dela</h3> : null}
          </div>
          {!gradesOnly(program) ? <span className='SchoolItem-data-source'>Det här programmet tar in på prov. Ofta adderas resultatet till antagningspoängen vilket gör att antagningspoängen kan verka högre än de egentligen är.</span> : null}
          <table>
            <tbody>
              <DataRow data={program.antSlut} showExtra={program.antSlut>340}>Antagningspoäng</DataRow>
              <DataRow data={program.medSlut} showExtraOrder={program.antSlut>program.medSlut&&!(program.antSlut>340)}>Medelpoäng</DataRow>
              {program.admissionNumbers ? <>
                <DataRow data={program.admissionNumbers.admitted} noShowP={true}>Antal antagna</DataRow>
                <DataRow data={program.admissionNumbers.available} noShowP={true}>Antal platser</DataRow>
                <DataRow data={program.admissionNumbers.free} noShowP={true}>Antal lediga platser</DataRow>
                <DataRow data={program.admissionNumbers.reserve} noShowP={true}>Antal reserv</DataRow>
              </> : <>
                <DataRow data={program.antRes}>Reservantagning</DataRow>
                <DataRow data={program.medRes}>Reservantagning Medelpoäng</DataRow>
                <DataRow data={program.antPrel}>Preliminär Antagning</DataRow>
                <DataRow data={program.medPrel}>Preliminär Medelpoäng</DataRow>
              </>}
              
            </tbody>
          </table>
        </div>
        {[recomend, highEdu, back, exitScore].some(a=>a) ? <h3>Om {program.program}</h3> : !error ? <Placeholder /> : null}
        <section>
          <ul className='SchoolItem-data-list'>
            {exitScore !== null && exitScore !== '.' ? <li>{exitScore} genomsnittligt avgångsbetyg (≈ {Number(exitScore.replace(',', '.'))+2.5} med full merit).</li> : null}
          {recomend !== null && recomend !== '-' ? <li>Eleverna rekomenderar {program.namn} <input className='SchoolItem-recomend-value SchoolItem-data-recomend-item' type="range" min="0"  max="10" value={recomend.replace(',', '.')} readOnly /><div className='SchoolItem-recomend-alternatives SchoolItem-data-recomend-item'>
            <span>inte alls </span>
            <span>ganska lite</span>
            <span>ganska mycket</span>
            <span>helt och hållet</span>
          </div>{/*{Math.round(recomend.replace(',', '.'))} av 10 elever rekomenderar skolan. */}</li> : null}
          {highEdu !== null ? <li>
            {highEdu}% har högutbildade föräldrar.
        </li> : null}
          {back !== null ? <li>
            {back}% av alla elever är kvinnor.
        </li> : null}
          {est !== null ? <li>
            {Math.round(est)}% av alla elever jobbar eller studerar 3 år efter examen.
        </li> : null}
        {/*inflString !== null ? <li>{inflString}</li> : null*/}
        </ul>
        </section>
        {(program !== undefined && !dev)  ? <ins className="adsbygoogle"
           style={{display:"block"}}
           data-ad-client="ca-pub-9298801151939351"
           data-ad-slot="8732166253"
           data-ad-format="auto"
           data-full-width-responsive="true">
         </ins>:null}
         <section style={{marginTop: '35px'}}>
           {feedback
           ? <p className='SchoolItem-feedback-container'>{
             badfeedback
              ? <>
                Vad var det som var dåligt?
                <input className='SchoolItem-feedback-input' type="text" value={badFeedbackValue} onChange={(e)=>{if (e.target.value.length > 100) {return}setBadFeedbackValue(e.target.value)}}/>
                <span className='SchoolItem-feedback-button' onClick={handleSendBadFeedback}>Skicka</span>
              </>
              : <span>Tack för din feedback!</span>}</p>
           : <p className='SchoolItem-feedback-container'>
              Var den här sidan till hjälp?
              <span className='SchoolItem-feedback-button SchoolItem-feedback-button-good' onClick={()=>sendFeedback(true)}>Ja</span>
              <span className='SchoolItem-feedback-button SchoolItem-feedback-button-bad' onClick={()=>sendFeedback(false)}>Nej</span>
            </p>
          }

        </section>
        <section className='SchoolItem-data-source-section'>
          <p className='SchoolItem-data-source'>
            Källa: Antagningsstatistik från respektive kommun (Göteborgsregionen, Gymnasieantagningen Stockholm, Skånegy).
            Bearbetad data från skolverket och SCB. Elevrekomendationer från skolinspektionens enkät.
            En elev har högutbildade föräldrar om minst en förälder har eftergymnasial utbildning.
            Genomsnittlig betygspoäng är inte samma mått som meritpoängen som används vid antagning till universitet och högskolor. I beräkningen av genomsnittligt avgångsbetyg ingår aldrig utökade kurser eller kurser som ger extra meritpoäng (exempelvis vissa kurser i moderna språk) till skillnad från meritpoängen vid antagning till universitet och högskolor.
            Med reservation för felaktig data.
          </p>
        </section>
        </article>
    )
}

const DataRow = ({data, infl, column, noShowP, children, showExtra, showExtraOrder}) =>
  <tr className={`SchoolItem-data-row-container ${column ? 'SchoolItem-data-row-container-column' : ''}`}>
    <td className='SchoolItem-data-row-title'>{children}</td>
    {infl !== undefined && infl !== 0 && infl !== null
      ? <span className='SchoolItem-data-row'><span className='SchoolItem-score-data'>{infl}</span><span className='SchoolItem-score-text'>bii</span></span>
      : null}
    <td className='SchoolItem-data-row'>
      <span className='SchoolItem-score-data'>{
        data === '1)' ? <Badge type={1} />
          : data === '2)' ? <Badge type={2} />
            : data === '3)' ? <Badge type={3} />
              : data === '4)' ? <Badge type={4} />
                : data === '5)' ? <Badge type={5} />
                  : (data === null || data === undefined) ? <Badge type={6} />
                    : data
      }
    </span>
      {isNaN(data) || data === null ? '' : noShowP ? '' : <span className='SchoolItem-score-text'> poäng</span>}
      {showExtra ? <span className='SchoolItem-score-extra-text'>Att antagninspoängen är över 340 kan bero på att resultat från t.ex. inträdesprov adderas till de vanliga betygen</span> : null}
      {showExtraOrder ? <span className='SchoolItem-score-extra-text'>Att antagninspoängen är högre än medelpoängen kan bero på att resultat från t.ex. inträdesprov adderas till antagningspoängen</span> : null}
    </td>
  </tr>

const matchCorrectProg = (data, program) => {
  let possible = data.filter(d => program.includes(d.programCode))
  if (possible.length < 1) {
    possible = data.filter(d => d.gradesPointsForStudents.schoolValues[0].valueType === 'EXISTS')
    if (possible.length > 0) {
      return possible[0].gradesPointsForStudents.schoolValues[0].value
    }
    return data[0].gradesPointsForStudents.schoolValues[0].value
  }
  return possible[0].gradesPointsForStudents.schoolValues[0].value
}

const gradeList = ['-', 'F', 'E', 'D', 'C', 'B', 'A']
const calcEquivalentGrade = (score) => {
  if(score > 340) {
    return ''
  }
  let topGrade = Math.ceil((((score / 17)-10)/2.5)+2)
  let topGradeScore = (10 + ((topGrade - 2) * 2.5))*17
  let diff = topGradeScore - score
  let lowGrade = Math.ceil(diff/2.5)
  return `${lowGrade===1 ? 'ett' : lowGrade + 'st'} ${gradeList[(topGrade-1)]} och resten ${gradeList[topGrade]}`
}

const Placeholder = () => {
  let [show, setShow] = useState(false)
  useEffect(()=>{
    let id = setTimeout(()=>{setShow(true)}, 300)
    return ()=>{
      clearTimeout(id)
    }
  })
  return <div className={`Placeholder-container ${!show ? 'Placeholder-hide' : ''}`}>
    <div className='Placeholder-breath Placeholder-title '/>
    <div className='Placeholder-breath Placeholder-text '/>
    <div className='Placeholder-breath Placeholder-title '/>
    <div className='Placeholder-breath Placeholder-text '/>
  </div>
}

export default SchoolItem
