import React from 'react'
import './Filter.css'

import Icon from './Icon/Icon'

const Filter = ({types, clicked, isClicked, title, ...props}) =>
  <div className='Filter'>
  <span className='Filter-title'>{title}</span>
  <div className='Filter-Container'>
    {types.map((t, i) => <Icon name={t} active={isClicked.includes(i)} clicked={() => clicked(i)} key={i} icon='' />)}
  </div>
</div>
export default Filter
