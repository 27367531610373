import React from 'react'
import Helmet from 'react-helmet'
import {Route, Link} from 'react-router-dom'
import ReactGA from 'react-ga'
import SchoolItem from '../SchoolList/SchoolItem/SchoolItem'
import './School.css'

const School = ({name, programs}) => {
  ReactGA.pageview(window.location.pathname + window.location.search)
  if (programs.length === 0) {
    return (
      <h3 className='SchoolList-noFound'>
        <img src='/notfound.svg' className='SchoolList-noFound-image' alt='question' />
        <span role='img' aria-label=':-('>Det verkar inte finnas någon skola som heter "{name}".<br/>Du kan <Link className='School-Link-Back' to='/'>leta bland alla skolor</Link></span>
      </h3>
    )
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>🎓Antagninspoäng 2020 - {name}</title>
        <meta name="description" content={`Antagningspoäng Gymnasieantagningen 2020 ${name}. ${programs.map(program => ` ${program.program}: ${program.antSlut}`)}`}></meta>
      </Helmet>
      <Route render={({history}) => {
          console.log(history);
          if (history.location.state && history.location.state.fromGood) {
            return <a className="about-link SchoolItem-back-link" onClick={()=>history.goBack()}>◀ Tillbaka</a>
          } else {
            return <Link className="about-link SchoolItem-back-link" to='/'>◀ Alla program</Link>
          }
        }}/>
      <h1>{name}</h1>
      <ul className='SchoolList-list'>
        {programs.map(program => progToItem(program))}
      </ul>
    </React.Fragment>
  )
}

const progToItem = (prog) => <SchoolItem
  key={prog.namn + prog.program}
  noShowSchool={true}
  school={prog.namn}
  program={prog.program}
  addFinal={prog.antSlut}
  avgFinal={prog.medSlut}
  addPrel={prog.antPrel}
  avgPrel={prog.medPrel}
  addReserv={prog.antRes}
  avgReserv={prog.medRes}
  urlString={prog.urlString}
  infl={prog.inflation !== undefined ? prog.inflation.high !== null && prog.inflation.high !== 0 ? prog.inflation.high.toLocaleString(undefined, {maximumFractionDigits: 1}) : null : null}
/>

export default School
