/* globals, navigator */
import React from 'react'
import {Link} from 'react-router-dom'
import './SchoolItem.css'
import gradesOnly from '../../utils/gradeOrTest.js'
import Badge from './Badge/Badge.js'

class SchoolItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {open: false}
    // console.log(props)
  }
  open () {
    this.setState({open: !this.state.open})
  }
  render () {
    const isBot = false ///bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)
    let title = (<React.Fragment><h3 className='SchoolItem-mini-school-title'>{this.props.school}</h3>
  <h4 className='SchoolItem-mini-school-program'>{this.props.program}</h4></React.Fragment>)
    if (this.props.noShowSchool) {
      title = (<h3 className='SchoolItem-mini-school-title'>{this.props.program}</h3>)
    }
    return (

      <li className={`SchoolItem-mini-item ${this.state.open || isBot ? 'SchoolItem-mini-open' : ''}`}>
        <Link to={{
            pathname: this.state.open ? '/' : `/program/${encodeURI(encodeURIComponent(this.props.urlString))}`,
            state: {fromGood: true}
          }} className='SchoolItem-layout-title'>
          <section>{title}</section>
        <DataRow data={this.props.addFinal} isBot={isBot} column>{!gradesOnly(this.props)?'Inte enbart betyg':null}</DataRow>
        </Link>
      </li>
    )
  }
}

const DataRow = ({data, infl, column, noShowP, isBot=false, children}) =>
  <div className={`SchoolItem-mini-data-row-container ${column ? 'SchoolItem-mini-data-row-container-column' : ''}`}>
    {infl !== undefined && infl !== 0 && infl !== null
      ? <div className='SchoolItem-mini-data-row'><div className='SchoolItem-mini-score-data'>{infl}</div><div className='SchoolItem-mini-score-text'>bii</div></div>
      : null}
    <div className='SchoolItem-mini-data-row'>
      <div className='SchoolItem-mini-score-data'>{
        data === '1)' ? <Badge type={1} />
          : data === '2)' ? <Badge type={2} />
            : data === '3)' ? <Badge type={3} />
              : data === '4)' ? <Badge type={4} />
                : data === '5)' ? <Badge type={5} />
                  : data === null ? <Badge type={6} />
                    : data
      }
      </div>
      <div className='SchoolItem-mini-score-text'>{isNaN(data) || data === null ? '' : noShowP ? isBot ? 'betygsinflation' : 'bii' : isBot ? 'poäng' : 'p.'}</div>
    </div>
    <span className='SchoolItem-mini-data-row-title'>{children}</span>
  </div>

export default SchoolItem
