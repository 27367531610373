import React from 'react'
import Helmet from 'react-helmet'
import {Link} from 'react-router-dom'
import ReactGA from 'react-ga'
import './GradeCalculator.css'

import GradePicker from './GradePicker/GradePicker'

class GradeCalculator extends React.Component {
  constructor (props) {
    super(props)

    this.nbrForGrade = () => props.programAdmission.filter(a => a <= sumGrades(this.state.grades)).length

    this.subjects = [
      {name: 'Bild'}, {name: 'Engelska'}, {name: 'Hem- och konsumentkunskap'},
      {name: 'Idrott och hälsa'}, {name: 'Matematik'}, {name: 'Musik'},
      {name: 'Biologi'}, {name: 'Fysik'}, {name: 'Kemi'}, {name: 'Geografi'}, {name: 'Historia'},
      {name: 'Religionskunskap'}, {name: 'Samhällskunskap'}, {name: 'Slöjd'},
      {name: 'Svenska', extraInfo:  'Alt. svenska som andraspråk'}, {name: 'Teknik'}, {name: 'Språkval'}, {name: 'Elevens val', extraInfo:  'T.ex. c-språk'}
    ]
    let savedState = window.localStorage.getItem('grades')
    this.state = savedState !== null ? {grades: JSON.parse(savedState)} : {grades: Array.from({length: this.subjects.length}, (_, i) => i === this.subjects.length - 1 ? 0 : 4)}
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  createCanvas() {
    ReactGA.event({
      category: 'GradeSave',
      action: 'Feedback',
      value: 1
    })
    console.log("Mount")
    let canvas = document.createElement('canvas')
    canvas.setAttribute('height', 1920)
    canvas.setAttribute('width', 1080)
    // let canvas = document.querySelector("#canvas")
    let ctx = canvas.getContext('2d')
    ctx.fillRect(10,10,10,10)
    let g = ctx.createLinearGradient(0, 0, canvas.width, canvas.height)
    g.addColorStop(0, '#e942f5')
    g.addColorStop(0.5, '#db2a59')
    g.addColorStop(0.8, '#bf1f7f')
    g.addColorStop(1, '#8634e3')
    ctx.fillStyle = g
    ctx.fillRect(0,0,canvas.width,canvas.height)

    ctx.font = '60px IBM Plex Sans'
    ctx.fillStyle = "white"

    
    let promo = "betygen.com"
    ctx.textAlign = 'right'
    ctx.textBaseline = 'bottom'
    // let txtWidth = ctx.measureText(text).width
    ctx.fillText(promo, canvas.width-20,canvas.height-20)

    let year = (new Date()).getFullYear()

    ctx.textAlign = 'right'
    ctx.textBaseline = 'top'
    // let txtWidth = ctx.measureText(text).width
    ctx.fillText(year, canvas.width-20,20)


    ctx.font = '90px IBM Plex Sans'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'


    ctx.font = 'bold 128px IBM Plex Sans'
    let text = sumGrades(this.state.grades) + " poäng"
    // let txtWidth = ctx.measureText(text).width
    ctx.fillText(text, canvas.width/2,400)

    ctx.font = '40px IBM Plex Sans'
    let {perc, numBetter} = calcPerc(sumGrades(this.state.grades))
    ctx.fillText('Top ' + perc + '% av alla elever', canvas.width/2,540)
    ctx.fillText('Bara ' + numBetter.toLocaleString() + ' elever har ' + (sumGrades(this.state.grades)>337.5 ? 'lika bra' : 'bättre') + ' betyg', canvas.width/2,620)

    ctx.font = '40px IBM Plex Sans'
    
    this.subjects.map((subj, i) => {
      ctx.textAlign = 'left'
      let name = subj.name
      let grade = ['-', 'F', 'E', 'D', 'C', 'B', 'A'][this.state.grades[i]]
      let padding = 40
      let x = padding
      let rows = 9
      if(i >= rows) {
        x = canvas.width/2 + padding
      }
      let exeption = 2
      
      if(i == exeption) {
        ctx.fillText(name.slice(0,9), x,800 + (i%rows)*100-20)  
        ctx.fillText(name.slice(9), x,800 + (i%rows)*100+20)  
      } else {
        ctx.fillText(name, x,800 + (i%rows)*100)
      }
      
      ctx.textAlign = 'right'
      
      ctx.fillText(grade, x + (canvas.width/2)-padding*2,800 + (i%rows)*100)
      
      
    })

    let a = document.createElement('a')
    a.setAttribute('download', 'betyg.png')
    a.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"))
    document.querySelector('body').appendChild(a)
    a.click()
    document.querySelector('body').removeChild(a)
      
  }

  render () {
    window.localStorage.setItem('grades', JSON.stringify(this.state.grades))
    window.localStorage.setItem('hasSetGrades', JSON.stringify(sumGrades(this.state.grades)))
    let {perc, numBetter} = calcPerc(sumGrades(this.state.grades))
    return (
      <div className={`GradeCalculatorContainer ${this.props.className}`}>
        <Helmet>
          <title>Räkna ut ditt meritvärde, se antagningspoäng</title>
          <meta name="description" content={"Fyll i dina betyg och få reda på ditt mertivärde. Se vilka gymnasieskolor du kommer in på. "+this.subjects.map(s => s.name).join(': A, ')}></meta>
        </Helmet>
        <h2 className='GradeCalculatorTitle'>Räkna ut ditt meritvärde</h2>
          <h3 className='GradeCalculatorExplTitle'>Hur fungerar meritvärde?</h3>
          <p className='GradeCalculatorExplText'>
          Meritvärdet är det samanlagda värdet av alla dina betyg. Betygen är värda:
          </p>
          <table className="App-overlay-table">
            <tbody>
              <tr><td>A =</td><td>20</td></tr>
              <tr><td>B =</td><td>17.5</td></tr>
              <tr><td>C =</td><td>15</td></tr>
              <tr><td>D =</td><td>12.5</td></tr>
              <tr><td>E =</td><td>10</td></tr>
              <tr><td>F =</td><td>0</td></tr>
              <tr><td>- =</td><td>0</td></tr>
            </tbody>
          </table>
          <p className='GradeCalculatorExplText'>
            I normalfallet summeras 16 betyg vilket innebär ett maximalt meritvärde på (16×20=) 320 poäng. Om man har 17 ämnen (t.ex. om man har språkval) kan man istället få (17×20=) 340 poäng.
            Elevens val (ofta c-språk) är ett speciellt ämne som fungerar som ett 18:de "bonusämne". Det läggs inte till meritvärdet men kan byta ut ett annat sämre ämne. Elevens val räknas alltså bara om det kan ersätta ett annat ämne med lägre betyg.
          </p>
          <h3 className='GradeCalculatorExplTitle'>Betygsräknare</h3>
        <p className='GradeCalculatorSub'>Du har <span className='GradeCalculatorGrade'>{sumGrades(this.state.grades)}</span> poäng</p>
        {this.subjects.map((subj, i) => <GradePicker key={i} grade={this.state.grades[i]} setGrade={({grade}) => {
          const tmpGrade = this.state.grades
          tmpGrade[i] = grade
          this.setState({grades: tmpGrade})
        }}>{subj.name}{subj.extraInfo!==undefined?<span  className='GradeCalculatorGradeExtraText'>{subj.extraInfo}</span>:null}</GradePicker>)}
        <p className='GradeCalculatorBottom'> = <span className='GradeCalculatorGrade'>{sumGrades(this.state.grades)}</span> poäng <span className='GradeCalculatorSaveGrades' onClick={()=>this.createCanvas()}><>Spara dina betyg som bild<img className='GradeCalculatorSaveGradesImgPreview' src='/betyg-img.jpg'/></></span><br/><Link className='Grade-see-which' to='/'>Se vilka skolor du kommer in på med {sumGrades(this.state.grades)} poäng</Link></p>
        <h2 className='GradeCalculatorExplTitle'>Hur bra är mitt meriätvärde?</h2>
        <p className='GradeCalculatorExplText'>
          Det går inte att säga om ett meriätvärde är bra eller dåligt.
          Det beror på vad du själv har för ambitioner och på vilken skola eller vilket program du vill gå på.
        </p>
        <h4 className='GradeCalculatorExplText'>Rent statistiskt går det dock att säga vissa saker:</h4>
        <p className='GradeCalculatorExplText'>
          - Du kommer in på fler än {Math.round(this.nbrForGrade()/10)*10} progam<br/>
          - Du är bättre än ≈{perc}% av alla elever i 9:an<br/>
          - Det är {numBetter > 50000 ? '' : 'bara'} {(Math.round(numBetter/1000)*1000).toLocaleString()} elever som har {sumGrades(this.state.grades)>337.5 ? 'lika bra' : 'bättre'} betyg<br/>
          - <Link className='Grade-see-which' to='/'>Du kan se vilka skolor du kommer in på med {sumGrades(this.state.grades)} poäng här</Link>
        </p>
      </div>
    )
  }
}

const sumGrades = (grades, extraIndex) => {
  const gradeValues = grades.map(g => gradeForNumber(g))
  const total = gradeValues.reduce((acc, cur) => cur + acc, 0)
  const extraVal = grades[extraIndex]
  if (extraVal === 0) {
    return total
  }
  const lowest = gradeValues.reduce((acc, cur) => Math.min(acc, cur))
  return total - lowest
}
const gradeForNumber = (i) => {
  switch (i) {
    case 0: {
      return 0
    }
    case 1: {
      return 0
    }
    default:
      return 10 + ((i - 2) * 2.5)
  }
}

const calcPerc = (grade) => {
  let nrStudents = 110*1000
  let percGrade = [20, 70, 127.5, 192.5, 240, 282.5, 312.5,  325,  337.5]
  let percNumber = [1, 5, 10, 25, 50, 75, 90,  95,  99]
  for (var i = percGrade.length-1; i > -1; i--) {
    if(percGrade[i]<=grade) {
      return {perc: percNumber[i], numBetter: nrStudents - ((percNumber[i]/100) * nrStudents)}
    }
  }
  return {perc: 0, numBetter: nrStudents}
}

export default GradeCalculator
