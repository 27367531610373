/* globals fetch */
import 'babel-polyfill'
import React, { Component } from 'react'
import {BrowserRouter as Router, Route, NavLink, Link, Switch, Redirect} from 'react-router-dom'
import ReactGA from 'react-ga'
import './App.css'
import GradeCalculator from './GradeCalculator/GradeCalculator'
import WelcomeHeader from './WelcomeHeader/WelcomeHeader'
import SchoolList from './SchoolList/SchoolList'
import Program from './Program/Program'
import School from './School/School'
import About from './About'
import Navigation from './Navigation/Navigation'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {data: [], quickData: [], hasSetData: false}
    fetch('/output.json')
      .then(res => res.json())
      .then((json) => {
        let data = []
        for (var munic in json.utbildningar[0]) {
          if (json.utbildningar[0].hasOwnProperty(munic)) {
            // eslint-disable-next-line
            data = data.concat(json.utbildningar[0][munic].map(prog => Object.assign(prog, {area: munic, namn: prog.namn || prog.name, name: prog.namn || prog.name, program: prog.program || prog.progam, inflation: prog.inflation || {low: 0, middle: 0, high: 0}})))
            data.map(prog =>  Object.assign(prog, {urlString: `${prog.namn} ${prog.program}`}))
          }
        }
        // console.log(printSitemap(data));
        this.setState({data, showOverlay: false, hasSetData: true})
      })

    ReactGA.initialize('UA-126130773-1', {testMode: document.location.hostname.search("betygen") === -1})
    console.log('GA test mode:', document.location.hostname.search("betygen") === -1)
  }
  programOrRedirect(name, data, hasSetData) {
    let match = mapMatchToProgram(name, data)
    if(match === undefined && hasSetData) {
      console.log("Redirecting")
      return <Redirect to='/' />
    }
    return <Program program={match}/>
  }
  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    const notActiveUrls = ['/betyg', '/about']
    return (
      <Router>
        <section>
          <WelcomeHeader />
          <Navigation />
        </section>
      <main role='main' className='App App-top'>
        <Switch>
          <Route path='/showAll' component={()=><SchoolList programs={this.state.data} showAll={true}/>}/>
          <Route path='/betyg' component={()=><GradeCalculator programAdmission={this.state.data.map(p => isNaN(Number(p.antSlut)) ? 0 : p.antSlut)}></GradeCalculator>}/>
          <Route path='/about' component={About}/>
          <Route path='/skola/:name' component={({match})=>
            <School name={decodeURIComponent(decodeURI(match.params.name))} programs={mapSchoolToPrograms(match.params.name, this.state.data)}></School>
          }/>
          <Route path='/program/:name' component={({match})=>
            this.programOrRedirect(match.params.name, this.state.data, this.state.hasSetData)
          }/>
          <Route path='/' component={()=><SchoolList programs={this.state.data} />}/>
        </Switch>


        </main>
        <footer className='App-footer'>
          <div>Made with <span role='img' aria-label='heart' className='App-emoji'>♥️</span></div>
          <div className='App-footer-sub'><a href="https://linuslofgren.se/">Linus von Ekensteen Löfgren</a>, linus[at]ekensteenlofgren.se, <Link to='/about' className='App-footer-sub-link'>vad är betygen.com?</Link></div>
      </footer>
    </Router>
    )
  }
}

const mapMatchToProgram = (match, programs) => {
  const matchedPrograms = programs.filter((program) => program.urlString === decodeURIComponent(match))
  return matchedPrograms[0]
}
const mapSchoolToPrograms = (match, programs) => {
  const matchedPrograms = programs.filter((program) => program.namn === decodeURIComponent(match))
  return matchedPrograms
}

// const filterPrograms = (term) => (program) => {
//   const args = term.split(' ')
//   const res = args.map((str) => program.namn.toLowerCase().replace(/ |\t/g, '').includes(str.toLowerCase()) || program.program.toLowerCase().replace(/ |\t/g, '').includes(str.toLowerCase()))
//   return res.every(a => a)
// }


const printSitemap = (data) => {
  const domain = 'https://www.betygen.com/'
  const staticUris = ['', 'about', 'showAll', 'betyg']

  const staticURLs = staticUris.map(u => domain + u)

  const dynamicUrls = data.map(d => domain + 'program/' + encodeURIComponent(d.urlString)).join('\n')
  const dynamicSchoolUrls = data.map(d => domain + 'skola/' + encodeURIComponent(d.name))

  return staticURLs.concat(dynamicUrls).concat([... new Set(dynamicSchoolUrls)]).join('\n')
}

export default App
