import React from 'react'
import {Link} from 'react-router-dom'

const About = () => <article>
  <Link className="about-link SchoolItem-back-link" to='/'>◀ Till alla program</Link>
  <h1>Vad är betygen.com?</h1>
  <p>
    Betygen.com är en samanställning av data från <a className="about-link" href="https://www.grkom.se/toppmenyn/dettajobbargrmed/skolautbildning/gymnasieantagningen">kommuner</a>, <a className="about-link" href="https://www.skolverket.se/skolutveckling/statistik">skolverket</a> och <a className="about-link" href="https://www.scb.se/om-scb/om-scb.se-och-anvandningsvillkor/oppna-data-api/api-for-skolenhetsregistret/">scb</a> om gymnasieskolor
    och antagningspoängen till deras program.
  </p>
  <h2>Betyg, meritpoäng, antagningspoäng, intagningspoäng</h2>
  <h3>Antagningspoäng</h3>
  <p>
    <a className="about-link" href='/'>Se vad alla skolor har för antagningspoäng</a>. Antagningspoängen hämtas från skolans kommun som publicerar datan flera gånger per år. Den vanliga antagningen presenteras oftast i juli medans
    reservantagningen presenteras i september. Det leder till att reservantagningsdata inte är tillgänglig förrän september.
    Vissa skolor har endast manuell antaging och då finns ingen antagningsstatitik.
    Om en skola har över 340 i antagningspoäng kan det bero på att den har någon form av inträdesprov som ger den sökande extrapoäng.
    Mer info finns på <a className="about-link" href="https://www.grkom.se/toppmenyn/dettajobbargrmed/skolautbildning/gymnasieantagningen">gr-kom's hemsida</a>.
  </p>
  <h3>Betygsräknare</h3>
  <p>
    På Betygen.com kan du <a className="about-link" href='/betyg'>räkna ut dina betyg och snabbt se ditt meritvärde. </a>
  Meritvärdet räknas ut genom att summera betygens poäng:
  </p>
  <table className="App-overlay-table">
    <tbody>
      <tr><td>A =</td><td>20</td></tr>
      <tr><td>B =</td><td>17.5</td></tr>
      <tr><td>C =</td><td>15</td></tr>
      <tr><td>D =</td><td>12.5</td></tr>
      <tr><td>E =</td><td>10</td></tr>
      <tr><td>F =</td><td>0</td></tr>
      <tr><td>- =</td><td>0</td></tr>
    </tbody>
  </table>
  <p>
    Endast 17 betyg räknas, om man har elevens val kan det därför bytas mot det lägsta betyget.
  </p>
  </article>

export default About
