import React from 'react'
import './Badge.css'

const Badge = ({ type }) => <div className={`Badge-container ${'Badge-color-' + type}`}>{
  type === 1 ? 'Alla antagna'
    : type === 2 ? 'Test'
      : type === 3 ? 'Ingen behörig'
        : type === 4 ? 'Görs ej'
          : type === 5 ? 'Manuell'
            : type === 6 ? 'Ingen data'
              : ''
}</div>

export default Badge

// 1): "Alla behöriga sökande är antagna. Se behörighetsregler på www.grkom.se/gymnasieantagningen under rubriken Gymnasievalet"
// 2): "Enbart test. Utbildningen har inte antagning på betyg utan enbart på test"
// 3): "Ingen antagen på poäng. Det finns ingen behörig sökande"
// 4): "Till Introduktionsprogram görs ej preliminärantagning"
// 5): "Placering sker manuellt, ingen antagningspoäng. Mottagande skola har gjort antagningen"
