import React, {useState} from 'react'
import ReactGA from 'react-ga'

const Feedback = ({defaultValue}) => {
  let [newSchool, setNewSchool] = useState(defaultValue)
  let [schoolFeedback, setSchoolFeedback] = useState('')
  const handleNewSchoolFeedback = (schoolName) => {ReactGA.event({
      category: 'SchoolFeedback: ' + schoolName.substring(0, 40),
      action: 'Feedback',
      value: 1
    })
    setSchoolFeedback(true)
  }
  return <><span>Finns skolan du letar efter inte med?</span>
  <p className='SchoolItem-feedback-container'>
    {schoolFeedback ? 'Tack för din feedback!' : <>Önska att skolan läggs till (så kanske den kommer med i framtiden)
    <input className='SchoolItem-feedback-input' type="text" placeholder='skolans namn' value={newSchool} onChange={(e)=>setNewSchool(e.target.value)}/>
    <span className='SchoolItem-feedback-button' onClick={()=>handleNewSchoolFeedback(newSchool)}>Skicka</span></>}

  </p></>
}

export default Feedback
