import React from 'react'
import {Link} from 'react-router-dom'
import ColorModeSwitch from '../ColorModeSwitch/ColorModeSwitch'
import './WelcomeHeader.css'

const WelcomeHeader = () =>
  <header className='WelcomeHeader-container' >
    <Link to='/'>
      <h1 className='WelcomeHeader-title'>Betygen</h1>
      <h2 className='WelcomeHeader-sub'>antagnings- och meritpoäng</h2>
    </Link>
    <ColorModeSwitch />
  </header>
export default WelcomeHeader
