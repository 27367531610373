/* globals, navigator */
import React from 'react'
import {Link, Route, Switch} from 'react-router-dom'
import gradesOnly from '../utils/gradeOrTest.js'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'
import './SchoolList.css'
import SchoolItem from './SchoolItem/SchoolItem.js'
import SearchBar from './SearchBar/SearchBar.js'
import Filter from './Filter/Filter.js'
import Feedback from './Feedback/Feedback.js'

class SchoolList extends React.Component {
  constructor (props) {
    super(props)
    let res = JSON.parse(window.localStorage.getItem('filterMunic'))
    if (res === undefined) {
      res = -1
    }
    this.state = {
      search: '',
      filterMunic:  res,
      sortBy:  JSON.parse(window.localStorage.getItem('sortBy')) || 0,
      filterTest: !!JSON.parse(window.localStorage.getItem('filterTest'))
    }
    this.munics = ['Göteborg', 'Stockholm', 'Malmö']
  }
  render () {
    ReactGA.pageview(window.location.pathname + window.location.search)
    return (
      <React.Fragment>
        <Helmet>
          <title>🎓Antagningspoäng Gymnasium</title>
          <meta name="description" content="Lista över Antagningspoäng Gymnasieantagningen 2020: Alla gymnasieprogram och gymnasieskolor i Stockholm, Göteborg och Malmö. Sök antagningspoäng, medelpoäng, reservantagning och annan antagnngsstatistik. Räkna ut ditt meritvärde och ta reda på var du kommer in i gymnasievalet."></meta>
        </Helmet>
        <Route path='/göteborg' component={()=>(
            <Helmet>
              <title>🎓Antagningspoäng Gymnasium Göteborg 2020</title>
              <meta name="description" content="Här finns antagningspoängen till alla gymnasieprogram och gymnasieskolor i Göteborg, Stockholm och Malmö. Sök antagningspoäng, medelpoäng, reservantagning och annan antagnngsstatistik. Räkna ut ditt meritvärde och ta reda på var du kommer in i gymnasievalet."></meta>
            </Helmet>
          )}></Route>
        <Route path='/stockholm' component={()=>(
            <Helmet>
              <title>🎓Antagningspoäng Gymnasium Stockholm 2020</title>
              <meta name="description" content="Här finns antagningspoängen till alla gymnasieprogram och gymnasieskolor i Stockholm, Göteborg och Malmö. Sök antagningspoäng, medelpoäng, reservantagning och annan antagnngsstatistik. Räkna ut ditt meritvärde och ta reda på var du kommer in i gymnasievalet."></meta>
            </Helmet>
          )}></Route>
        <Route path='/skåne' component={()=>(
            <Helmet>
              <title>🎓Antagningspoäng Gymnasium Skåne 2020</title>
              <meta name="description" content="Här finns antagningspoängen till alla gymnasieprogram och gymnasieskolor i Skåne, Stockholm och Göteborg. Sök antagningspoäng, medelpoäng, reservantagning och annan antagnngsstatistik. Räkna ut ditt meritvärde och ta reda på var du kommer in i gymnasievalet."></meta>
            </Helmet>
          )}></Route>
          <Route path='/hogst-antagning-gymnasiet' component={()=>(
            <Helmet>
              <title>🎓Höst Antagningspoäng till Gymnasium 2020</title>
              <meta name="description" content="Gymnasieprogrammen med högst antagningspoäng 2020. Här finns programmen som är svprast att komma in på."></meta>
            </Helmet>
          )}></Route>
        <h1>Sök Antagningspoäng</h1>
        <p className='SchoolList-description'>Sök efter en gymnasieskola eller ett gymnasieprogram och se vad antagningspoängen var förra terminen</p>
      <ul className={`SchoolList-list ${this.props.className}`}>
          <Route render={({history})=>(
            <SearchBar search={(search) => {search==='' ? history.push('') : history.push('/search/'+search)}} isClicked={this.state.sortByTop} clicked={() => this.setState({sortByTop: !this.state.sortByTop})} />
            )} />
            <section className='SchoolList-filters' >
              <Filter types={this.munics} title='Visa bara resultat i' isClicked={[this.state.filterMunic]} clicked={(i) => {this.setState({filterMunic: this.state.filterMunic === i ? -1 : i}); window.localStorage.setItem('filterMunic', this.state.filterMunic === i ? -1 : i)}} />
              <Filter types={['Högst betyg', 'Lägst betyg', 'Skola']} title='Sortera på' isClicked={[this.state.sortBy]} clicked={(i) => {this.setState({sortBy: this.state.sortBy === i ? -1 : i}); window.localStorage.setItem('sortBy', this.state.sortBy === i ? -1 : i)}} />
            </section>
            <span className="SchoolItem-data-source SchoolList-popular-description">Betygen.com använder kakor från Google för att se till så allt fungerar</span>
          <Route path='/search/:term' component={()=><p className='SchoolList-description SchoolList-short-help'>Använd '+' för att söka på flera olika saker samtidigt, t.ex. 'natur + teknik'</p>} />

          <Route path='/search/:term' component={({match, history})=>(
              <React.Fragment>
              <p className='SchoolList-showing-results'>Visar resultat för "{match.params.term}"</p>
              <Link to='/'>Rensa sökning</Link>
              </React.Fragment>
            )} />
        <Switch>
          <Route path='/search/:term' component={({match})=>(
            <React.Fragment>
            {this.props.programs.filter(byMunic(this.state.filterMunic)).filter(filterPrograms(match.params.term)).length > 0
              ?
                  this.props.programs.filter(byMunic(this.state.filterMunic)).filter(filterPrograms(match.params.term)).sort(topProg(this.state.sortBy)).map(progToItem)
              : (
                <h3 className='SchoolList-noFound'>
                  <img src='/notfound.svg' className='SchoolList-noFound-image' alt='question' />
                  <span role='img' aria-label=':-('>
                    Hittade inte "{match.params.term}" {this.state.filterMunic !== -1 ? <>
                      i {this.munics[this.state.filterMunic]}
                    </> : ''}
                  </span>
                  {this.state.filterMunic !== -1 ?<div className='SchoolList-search-whole-button' onClick={()=>{this.setState({filterMunic: -1})}}>Leta i hela Sverige istället</div>:null}
                  <br/>
                  <Feedback defaultValue={match.params.term}></Feedback>
                </h3>
              )}
            </React.Fragment>
            )}/>
          <Route path='/showAll' component={()=>(
              <React.Fragment>
                <h3 className='SchoolList-popular' key='popular'>Alla program</h3>
                {this.state.filterTest ? null : <span>Visar även program där antagningsprov ingår i antagningspoängen</span>}
                <span className='Filter-Icon-Container Filter-Icon-Active' onClick={()=>{this.setState((prevState) => {window.localStorage.setItem('filterTest', !prevState.filterTest); return ({filterTest: !prevState.filterTest})})}}>{this.state.filterTest ? 'Visa program med antagningsprov' : 'Visa bara program utan antagningsprov'}</span>
                {this.props.programs.filter((prog) => {if(!this.state.filterTest) {return true}; return notAbove340(prog)}).filter(byMunic(this.state.filterMunic)).sort(topProg(this.state.sortBy)).map(progToItem)}
              </React.Fragment>
            )}/>
          <Route component={()=>(
            <React.Fragment>
              <Route path='/hogst-antagning-gymnasiet' component={()=>(
                <div style={{display: 'flex', flexDirection: 'column'}}>
                <h4 style={{marginBlock: 2}}>Vilka program har högst antagningspoäng?</h4>
                <h5 style={{marginBlock: 2}}>Här är programmen med högst antagningspoäng, alltså de program som är svårast att komma in på.</h5>
                <h5 style={{marginBlock: 2}}>Programmens poäng beror på hur stor söktrycket är för just det programmet, samt hur många platser det finns.<br/>
                Bara för att ett program har högre poäng betyder det inte att det är bättre än något annat program.</h5>
                <h4 style={{marginBlock: 2, marginBlockStart: 8}}>Hur räknar man ut hur många meritpoäng man har?</h4>
                <p style={{marginBlock: 2}}><Link to="/betyg" style={{textDecoration: 'underline'}}>Här kan du enkelt räkna ut ditt meritvärde.</Link></p>
                </div>
              )}></Route>
              <h3 className='SchoolList-popular' key='popular'>Populära program</h3>
              <p className='SchoolList-description SchoolList-popular-description'>Programmen med högst antagningspoäng och söktryck</p>
              {
                this.props.programs.filter(byMunic(this.state.filterMunic)).filter(notAbove340).length > 8
                ? this.props.programs.filter(byMunic(this.state.filterMunic)).filter(notAbove340).sort(topProg(0)).slice(0, 9).sort(topProg(this.state.sortBy)).map(progToItem)
                : <h3 key={'no-found'} className='SchoolList-noFound'>Sök på en skola eller program för att visa antagningspoäng</h3>
              }
              <Link to='/showAll'>Visa alla program</Link>
            </React.Fragment>
            )}/>
        </Switch>
      </ul>
    </React.Fragment>
    )
  }
}

const progToItem = (prog) => <SchoolItem
  key={prog.namn + prog.program}
  school={prog.namn}
  program={prog.program}
  addFinal={prog.antSlut}
  avgFinal={prog.medSlut}
  addPrel={prog.antPrel}
  avgPrel={prog.medPrel}
  addReserv={prog.antRes}
  avgReserv={prog.medRes}
  urlString={prog.urlString}
  infl={prog.inflation !== undefined ? prog.inflation.high !== null && prog.inflation.high !== 0 ? prog.inflation.high.toLocaleString(undefined, {maximumFractionDigits: 1}) : null : null}
/>
const filterPrograms = (term) => (program) => {
  let terms = term.split('+')
  return terms.some(t => match(t)(program))
}
const match = (term) => (program) => {
  const args = term.split(' ')
  const res = args.map((str) => program.namn.toLowerCase().replace(/ |\t/g, '').includes(str.toLowerCase()) || program.program.toLowerCase().replace(/ |\t/g, '').includes(str.toLowerCase()))
  return res.every(a => a)
}
const notAbove340 = gradesOnly
const byMunic = (munic) => (prog) => {
  switch (munic) {
    case 0:
      return prog.area.toLowerCase() === 'göteborg'
    case 1:
      return prog.area.toLowerCase() === 'stockholm'
    case 2:
      return prog.area.toLowerCase() === 'malmö'
    default:
      return true
  }
}
const topProg = (sortType) => (a, b) => {
  if(sortType === 0) {
    const aProg = isNaN(a.antSlut) || a.antSlut === null ? 0 : a.antSlut
    const bProg = isNaN(b.antSlut) || b.antSlut === null ? 0 : b.antSlut
    return bProg - aProg
  }
  if (sortType === 1) {
    const aProg = isNaN(a.antSlut) || a.antSlut === null ? 0 : a.antSlut
    const bProg = isNaN(b.antSlut) || b.antSlut === null ? 0 : b.antSlut
    return aProg - bProg
  }
}

export default SchoolList
