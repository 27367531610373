const gradesOnly = (prog) => {
  let antSlut = prog.antSlut || prog.addFinal
  let medSlut = prog.medSlut || prog.avgFinal
  let limit = 340
  let antSlutNormal = (isNaN(antSlut) || antSlut === null || antSlut <= limit)
  let antLessThanMed = ((antSlut <= medSlut || (isNaN(antSlut) || antSlut === null)) || medSlut === null)
  let medLessThanLimit = medSlut <= limit || medSlut === null
  // console.log(prog)
  // console.log(antSlutNormal)
  // console.log(antLessThanMed)
  // console.log(medLessThanLimit)
  return antSlutNormal && antLessThanMed && medLessThanLimit
}

export default gradesOnly
