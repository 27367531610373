import React from 'react'
import './SearchBar.css'

import SortBy from './SortBy/SortBy'
const phSchools = ['Norra Real', 'Göteborgs Högre Samskola', 'Polhem', 'Rudebecks', 'Hvitfeldtska', 'Södra Latin', 'ProCivitas', 'S:t Petri skola', 'Viktor Rydberg Gymnasium']
const importRandom = Math.random()
const SearchBar = ({school, prog, add, search, clicked, isClicked, ...props}, timeout) =>
  // eslint-disable-next-line
  <form onSubmit={(e)=>{console.log('sub');document.querySelector("input").blur();e.preventDefault();}} className='SearchBar-input-form'>
    <input aria-label='Sök gymnasieskola' type='search' placeholder={
        `Testa med "${phSchools[Math.floor((importRandom*phSchools.length))]}"`
      } onChange={debounce(inputHandler(search),200)} className='SearchBar-input' />
  </form>

const inputHandler = (search) => (val) => {
  search(val)
}

const debounce = (fn, duration) =>  {
  let  timerId;
  return (e) => {
    clearTimeout(timerId)
    let val = e.target.value
    timerId = setTimeout(()=>fn(val), duration)
  }
}

export default SearchBar
