import React from 'react'

import './GradePicker.css'

const GradePicker = ({children, grade, setGrade}) => (
  <div className='GradePicker-container'>
    <div className='GradePicker-subj'>{children}</div>
    <div className='GradePicker-grades'>
      {['-', 'F', 'E', 'D', 'C', 'B', 'A'].map((g, i) => <div key={i} onClick={() => setGrade({grade: i, subj: children})} className={`GradePicker-grade GradePicker-grade-${i} ${i === grade ? 'GradePicker-active' : ''}`}>{g}</div>)}
    </div>
  </div>
)

export default GradePicker
