import React, {useState} from 'react'
import './ColorModeSwitch.css'

const ColorModeSwitch = () => {
  let customSet = window.localStorage.getItem('color-mode')
  let initial = customSet !== null ? customSet : 'auto'
  let [colorMode, setColorMode] = useState(initial)
  if (customSet !== null) {
    document.documentElement.setAttribute('data-color-mode', customSet)
  }
  const updateColorMode = (mode) => {
    if(mode === undefined) {
      document.documentElement.removeAttribute('data-color-mode')
      window.localStorage.removeItem('color-mode')
      setColorMode('auto')
      return
    }
    document.documentElement.setAttribute('data-color-mode', mode)
    window.localStorage.setItem('color-mode', mode)
    setColorMode(mode)
  }
  const colorClass = (colorMode, inp) => colorMode === inp ? 'ColorModeSwitch-active' : ''
  return (
    <div className='ColorModeSwitch-container'>
      <div className={`ColorModeSwitch-Item ${colorClass(colorMode, 'dark')}`} onClick={()=>updateColorMode('dark')}>
        <span>🌒</span><span className='ColorModeSwitch-small'>dark</span>
      </div>
      <div className={`ColorModeSwitch-Item ${colorClass(colorMode, 'auto')}`} onClick={()=>updateColorMode()}>
        <span>🌓</span><span className='ColorModeSwitch-small'>auto</span>
      </div>
      <div className={`ColorModeSwitch-Item ${colorClass(colorMode, 'light')}`} onClick={()=>updateColorMode('light')}>
        <span>🌔</span><span className='ColorModeSwitch-small'>light</span>
      </div>
    </div>
  )
}

export default ColorModeSwitch
