/* globals fetch */
import 'babel-polyfill'
import React, { Component } from 'react'
import {BrowserRouter as Router, Route, NavLink, Link, Switch} from 'react-router-dom'
import './Navigation.css'

class Navigation extends Component {
  render () {
    const notActiveUrls = ['/betyg', '/about']
    return (
        <nav className='Navigation'>
          <Route render={({history}) =>
            <>
              {(history.location.state && history.location.state.fromGood)
                ?<a className={!notActiveUrls.some(p => history.location.pathname === p)?'App-nav-active':''} onClick={()=>history.goBack()}>Antagningspoäng</a>
                :<NavLink activeClassName='App-nav-active' to="/" isActive={(m, l)=>!notActiveUrls.some(p => l.pathname === p)}>Antagningspoäng</NavLink>
              }
              <NavLink activeClassName='App-nav-active' to={{
                  pathname: '/betyg',
                  state: {fromGood: !notActiveUrls.some(p => history.location.pathname === p)}
                }}>
                {JSON.parse(window.localStorage.getItem('hasSetGrades')) !== null ? <span className='App-grade-small-pill'>{JSON.parse(window.localStorage.getItem('hasSetGrades'))} poäng</span> : null}
                Räkna ut meritvärde
              </NavLink>
              <NavLink activeClassName='App-nav-active' to={{
                  pathname: '/about',
                  state: {fromGood: !notActiveUrls.some(p => history.location.pathname === p)}
                }}>
                Om Betygen.com
              </NavLink>
            </>
          }/>
        </nav>
    )
  }
}

export default Navigation
